<template>
  <div class="action-container shadow-sm d-flex justify-content-md-between justify-content-center flex-column flex-lg-row align-items-center col-sm-12 col-lg-10">
    <div class="text-center text-lg-left">
      <span v-if="title" class="d-block font-weight-bold">{{ title }}</span>
      <span v-if="subTitle" class="d-block text-muted small mt-2">{{ subTitle }}</span>
      <router-link v-if="href && btnText" class="mt-4 btn btn-dark" :to="href" @click.native="handleClick">
        {{ btnText }}
      </router-link>
    </div>
    <img v-if="imgSrc" width="140" class="mr-0 mr-lg-4 mt-4 mt-lg-0 text-right" :src="imgSrc" :alt="alt">
    <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path :fill="fill" fill-opacity="0.5" d="M0,192L48,213.3C96,235,192,277,288,282.7C384,288,480,256,576,224C672,192,768,160,864,170.7C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
    </svg>
    <div v-if="showWarning" class="warning-icon bg-warning shadow-sm">
      <alert-triangle-icon size="1.5x" class="custom-class" color="white"></alert-triangle-icon>
    </div>
  </div>
</template>

<script>
import { AlertTriangleIcon } from 'vue-feather-icons'

// TODO this component can be improved with more options and wave effects etc :)

export default {
  components: {
    AlertTriangleIcon
  },
  props: {
    title: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    },
    href: {
      type: String,
      required: false
    },
    btnText: {
      type: String,
      required: false
    },
    imgSrc: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      required: false
    },
    fill: {
      type: String,
      required: false,
      default: '#cbd5e0'
    },
    showWarning: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.action-container {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 188px;
  max-width: 900px;
  padding: 1.5rem 2rem;
  font-size: 1.4rem;
  background: transparent;
  border-radius: 8px;

  .wave {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 8px;
  }
}

.warning-icon {
  position: absolute;
  right: -20px;
  top: -20px;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    right: auto;
    top: -28px;
  }
}
</style>
