<template>
  <div class="container container-left">
    <h1 class="mb-5">Bienvenue!</h1>

    <p>Afin de compléter la mise en place de votre boutique, nous devons vous poser quelques questions sur votre entreprise.</p>
    <p>Le processus ne devrait pas prendre plus que quelques minutes.</p>
    <p>Vous pourrez également compléter ces étapes ultérieurement afin de découvrir notre plateforme dès maintenant!</p>

    <button class="btn btn-large btn-primary mt-4" @click="handleStart">{{ ctaLabel }}</button>
  </div>
</template>

<script>
import onboarding from '@/mixins/onboarding'

export default {
  mixins: [onboarding],
  computed: {
    ctaLabel () {
      return this.currentStep === 'legalProfile' ? 'Commencer' : 'Continuer'
    }
  },
  methods: {
    handleStart () {
      this.goToStepUrl()
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
}
</style>
