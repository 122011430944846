<template>
  <app-layout>
    <div class="px-4 p-sm-5 container w-100">
      <template v-if="!isRegistrationCompleted && !hasSkippedOnboarding">
        <start-onboarding-message></start-onboarding-message>
      </template>

        <div v-else class="px-0 container container-left">
          <h1 class="welcome-title mb-5">Bienvenue sur Le Bon Panier,</h1>

          <div class="row">
            <div class="col-12 col-lg-9">
              <div class="welcome-message">
                <p>Nous sommes fiers de vous accueillir sur notre plateforme de vente en ligne destinée à promouvoir les entrepreneurs du Québec.</p>
                <p>Vous faites partie des premiers vendeurs à vous inscrire sur notre site, et à ce titre votre retour d'expérience est important pour nous.</p>
                <p>N'hésitez pas à nous contacter si vous avez des questions, des suggestions ou encore pour nous proposer de nouvelles fonctionnalités.</p>
              </div>
            </div>
          </div>

          <div class="mt-4 row">
            <div class="col-12 col-lg-9">
              <message-composer @submit="sendMessage" />
            </div>
          </div>

          <div class="mt-5 row" v-if="hasSkippedOnboarding">
            <call-to-action
              title="Votre boutique est en mode brouillon et n'est pas visible publiquement."
              sub-title="Vous devez finaliser les étapes d'inscription pour publier votre boutique."
              href="/"
              btn-text="Compléter mon inscription"
              :img-src="require('@/assets/images/icons/draft.svg')"
              alt="Compléter mon inscriptre boutition"
              fill="rgba(244,161,0,0.4)"
              :show-warning="true"
              @click="handleSubscription"
            />
          </div>
          <div class="mt-5 row" v-if="!products.length && !isProductsLoading">
            <call-to-action
              title="Remplissez votre inventaire dès maintenant!"
              href="/products"
              btn-text="Ajoutez votre premier produit"
              :img-src="require('@/assets/images/icons/product.svg')"
              alt="Créez votre premier produit"
            />
          </div>
        </div>
    </div>
  </app-layout>
</template>

<script>
import onboarding from '@/mixins/onboarding'
import userData from '@/mixins/user-data'
import StartOnboardingMessage from '@/components/onboarding/StartOnboardingMessage'
import MessageComposer from '@/components/common/MessageComposer'
import http from '@/utils/http'
import CallToAction from '@/components/CallToAction'

export default {
  mixins: [onboarding, userData],
  components: {
    CallToAction,
    StartOnboardingMessage,
    MessageComposer
  },
  computed: {
    isProductsLoading () {
      return this.$store.state.products.isFetching
    },
    products () {
      return this.$store.state.products.paginatedData.data
    }
  },
  methods: {
    async sendMessage (message, onSuccess, onError) {
      try {
        await http.post('/v1/support/requests', {
          message,
          storeId: this.currentStoreId
        })

        onSuccess()
      } catch {
        onError()
        this.$toasted.error('Erreur lors de l\'envoi. Veuillez réessayer.')
      }
    },
    async handleSubscription () {
      try {
        await http.patch(`/v1/stores/${this.currentStoreId}/skip-onboarding`, { hasSkippedOnboarding: false })
        this.$store.commit('SKIP_REGISTRATION', false)
        this.goToStepUrl()
      } catch {
        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    }
  },
  created () {
    this.$store.dispatch('products/fetch', { storeId: this.currentStoreId })
  }
}
</script>

<style scoped lang="scss">
.welcome-title {
  font-size: 2rem;
}
.welcome-message {
  max-width: 800px;
  font-size: 16px;
}
</style>
